import * as React from 'react'
import { Link } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import ScheduleButton from '../components/schedule.button';

const ContactPage = () => {
  return (
    <Layout pageTitle="Contact Us">
      <h1>Contact Us</h1>
      <p>If you have any questions or would like to learn more about our services, please feel free to contact us using the information below:</p>

      <h2>Schedule a meeting</h2>
      <ScheduleButton />

      <h2>LinkedIn</h2>
      <p>You can connect with Nick Hortovanyi on LinkedIn at <a href="https://www.linkedin.com/in/hortovanyi/">linkedin.com/in/hortovanyi/</a></p>

      <h2>Twitter</h2>
      <p>You can reach out to our founder Nick Hortovanyi on Twitter at <a href="https://twitter.com/hortovanyi">@hortovanyi</a></p>

      <h2>Phone</h2>
      <p>You can also reach us by phone at <a href="tel:+61416426938">+61 416 426 938</a>.</p>

      <h2>Email</h2>
      <p>You can send us an email at <a href="mailto:contact@toasttechnology.com.au">contact@toasttechnology.com.au</a>.</p>

      <h2>Location</h2>
      <p>Our office are located on the Gold Coast, Australia</p>
    </Layout>
  )
}

export const Head = () => <Seo description="Contact Us" title="Contact Us" />

export default ContactPage