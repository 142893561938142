import React from "react";

const ScheduleButton = () => {
  const handleScheduleClick = () => {
    window.location.href = "https://calendly.com/nick-toast-technology/30min";
  };

  return (
    <button onClick={handleScheduleClick}>
      Schedule Consultation
    </button>
  );
};

export default ScheduleButton;
